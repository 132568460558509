<template>
	<div class="technologiesContainer" ref="technologiesContainer">
		<div class="container">
			<div class="blockLeftTitle">
				<div class="blockLeftTitle__left">
					<div class="mainTitle2">
						<p class="h2 animationBlockFadeUp" v-html="content.title" v-if="content.title"></p>
						<p class="mainTitle2--desc color-gray animationBlockFadeUp show1200" v-html="content.desc" v-if="content.desc"></p>
					</div>
				</div>
				<div class="blockLeftTitle__right">
					<p class="color-gray mb60 animationBlockFadeUp hide1200" v-html="content.desc" v-if="content.desc"></p>
					<div class="technologies">
						<div class="technologies__item animationBlockFadeUp" v-for="(item, index) in content.items" :class="{disabled: item.comingSoon}">
							<p class="technologies--title" v-html="item.title"></p>
							<ul class="technologies__items">
								<li v-for="(technology, index2) in item.technologies" v-html="technology"></li>
							</ul>
							<p class="technologies--comingSoon" v-if="item.comingSoon"><span class="icon-clock"></span>Скоро запуск</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<style lang="sass">
	@import '@/assets/new_sass/Technologies'
</style>

<script>
	export default {
		props: ['content'],
		data: () => ({
		}),
		mounted(){
		},
		methods: {
		}
	}
</script>
